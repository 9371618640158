import {
  Card,
  Grid2 as Grid,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { Subscription } from "./Home";

const StyledStack = styled(Stack)({
  display: "flex",
  alignItems: "center",
});

const calc30DayDiffTotal = (data: Subscription[]) => {
  const theme = useTheme();
  const currentDate = new Date();
  const date = new Date(currentDate);
  date.setDate(date.getDate() - 30);

  const activeSubsNow = data.filter(
    (s) => s.status === "ACTIVE" && new Date(s.startDate) <= currentDate
  ).length;
  const activeSubs30DaysAgo = data.filter(
    (s) => s.status === "ACTIVE" && new Date(s.startDate) <= date
  ).length;

  const canceledSubsNow = data.filter(
    (s) => s.status === "CANCELED" && new Date(s.startDate) <= currentDate
  ).length;
  const canceledSubs30DaysAgo = data.filter(
    (s) => s.status === "CANCELED" && new Date(s.startDate) <= date
  ).length;

  const numActive = (
    ((activeSubsNow - activeSubs30DaysAgo) / activeSubs30DaysAgo) *
    100
  ).toFixed(1);
  const numCanceled = (
    ((canceledSubsNow - canceledSubs30DaysAgo) / canceledSubs30DaysAgo) *
    100
  ).toFixed(1);

  return {
    active: {
      text: +numActive > 0 ? "+" + numActive + "%" : "-" + numActive + "%",
      color:
        +numActive > 0 ? theme.palette.success.main : theme.palette.error.main,
    },
    canceled: {
      text:
        +numCanceled > 0 ? "+" + numCanceled + "%" : "-" + numCanceled + "%",
      color:
        +numCanceled > 0
          ? theme.palette.error.main
          : theme.palette.success.main,
    },
  };
};

export const StatBar = (props: { data: Subscription[]; status: string }) => {
  const subs = calc30DayDiffTotal(props.data);
  const theme = useTheme();

  return (
    <Card variant="outlined" sx={{ width: "100%", p: 4 }}>
      <Grid container spacing={2} columns={3} sx={{ px: 4 }}>
        <Grid size={{ md: 2, lg: 1 }}>
          <Typography variant="h5">Active</Typography>
          <StyledStack direction="row" spacing={1}>
            <Typography variant="h3">
              {props.data.filter((s) => s.status === "ACTIVE").length}
            </Typography>
            <Typography color={subs.active.color}>
              {subs.active.text}
            </Typography>
          </StyledStack>
        </Grid>
        <Grid size={{ md: 2, lg: 1 }}>
          <Typography variant="h5">Canceled</Typography>
          <StyledStack direction="row" spacing={1}>
            <Typography variant="h3">
              {props.data.filter((s) => s.status === "CANCELED").length}
            </Typography>
            <Typography color={subs.canceled.color}>
              {subs.canceled.text}
            </Typography>
          </StyledStack>
        </Grid>
        <Grid size={{ md: 2, lg: 1 }}>
          <Stack>
            <Typography variant="h5">Status</Typography>
            <Typography
              variant="h4"
              color={
                props.status === "Online"
                  ? theme.palette.success.main
                  : theme.palette.error.main
              }
            >
              {props.status}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};
