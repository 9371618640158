import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid2 as Grid,
  Divider,
} from "@mui/material";
import { Circle } from "@mui/icons-material";

export const StatusCard = (props: {
  server: {
    serverName: string;
    status: string;
    cpuUsage: number;
    memoryUsage: number;
    uptime: number;
    responseTime: number;
  };
}) => {
  const { serverName, status, cpuUsage, memoryUsage, uptime, responseTime } =
    props.server;

  const isOnline = status === "Online";
  const statusColor = isOnline ? "green" : "red";

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold">
            {serverName}
          </Typography>
          <Circle sx={{ color: statusColor }} />
        </Grid>

        <Box mt={2}>
          <Grid container spacing={6} columns={1}>
            <Grid container size={1} columns={5}>
              <Grid size={2}>
                <Typography variant="subtitle1" color="textSecondary">
                  CPU Usage
                </Typography>
                <Typography variant="h6">{cpuUsage}%</Typography>
              </Grid>
              <Grid size={3}>
                <Typography variant="subtitle1" color="textSecondary">
                  Memory Usage
                </Typography>
                <Typography variant="h6">{memoryUsage}%</Typography>
              </Grid>
            </Grid>
            <Grid container size={1} columns={5}>
              <Grid size={2}>
                <Typography variant="subtitle1" color="textSecondary">
                  Uptime
                </Typography>
                <Typography variant="h6">{uptime}%</Typography>
              </Grid>
              <Grid size={3}>
                <Typography variant="subtitle1" color="textSecondary">
                  Response Time
                </Typography>
                <Typography variant="h6">{responseTime} ms</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography
          variant="caption"
          color="textSecondary"
          sx={{ display: "block", mt: 2 }}
        >
          Last Updated: Just now
        </Typography>
      </CardContent>
    </Card>
  );
};
