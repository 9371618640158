import Box from "@mui/material/Box";
import SideMenu from "./components/SideMenu";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createTheme, CssBaseline, PaletteMode, Stack } from "@mui/material";
import { Home } from "./components/pages/home/Home";
import { Customers } from "./components/pages/customers/Customers";
import { ThemeProvider } from "@emotion/react";
import { useState } from "react";
import useKeycloak from "./useKeycloak";

export default function App() {
  const [mode, setMode] = useState<PaletteMode>("light");
  const keycloak = useKeycloak();

  const theme = createTheme({
    palette: {
      mode: mode,
    },
  });

  const handleChangeMode = () => {
    setMode(mode === "light" ? "dark" : "light");
  };

  const handleLogout = () => {
    keycloak?.logout();
  };

  const getRoles = () => {
    if (keycloak?.resourceAccess) {
      return keycloak.resourceAccess["pwelt-portal"].roles;
    }
    return [];
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: "flex" }}>
        <BrowserRouter>
          <SideMenu
            changeMode={handleChangeMode}
            handleLogout={handleLogout}
            keycloak={keycloak}
          />
          <Box component="main" sx={{ flexGrow: 1 }}>
            <Stack spacing={2} sx={{ alignItems: "center" }}>
              <Routes>
                <Route
                  path="/"
                  element={
                    getRoles().includes("client-admin") ? (
                      <Home keycloak={keycloak} />
                    ) : (
                      <>client-user</>
                    )
                  }
                />
                <Route
                  path="/customers"
                  element={
                    getRoles().includes("client-admin") && (
                      <Customers keycloak={keycloak} />
                    )
                  }
                />
              </Routes>
            </Stack>
          </Box>
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  );
}
