import { Card, CardContent, Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { DashboardData } from "./Home";

const calcTotalUSersLastThirtyDays = (
  data: DashboardData[],
  interval: number
) => {
  const chartData: { date: number; total: number }[] = [];
  const currentDate = new Date();

  for (let i = 0; i <= interval; i++) {
    const date = new Date(currentDate);
    date.setDate(date.getDate() - i);
    const timeStamp = date.getTime();

    const dailyCount = data.filter(
      (item) => new Date(item.createdAt) <= date
    ).length;
    chartData.push({ date: timeStamp, total: dailyCount });
  }
  return chartData.reverse();
};

export const UsersGraph = (props: {
  data: DashboardData[];
  interval: number;
}) => {
  return (
    <Card variant="outlined" sx={{ width: "100%" }}>
      <CardContent>
        <Typography component="h2" variant="subtitle1">
          Total Users
        </Typography>
        <LineChart
          dataset={calcTotalUSersLastThirtyDays(props.data, props.interval)}
          xAxis={[
            {
              id: "date",
              dataKey: "date",
              valueFormatter: (date: Date) => {
                const temp: string[] = new Date(date).toDateString().split(" ");
                return temp[1] + " " + temp[2];
              },
              scaleType: "point",
              tickInterval: (indedx, i) => (i + 1) % (props.interval / 5) === 0,
            },
          ]}
          series={[
            {
              id: "Total",
              label: "Total Users",
              dataKey: "total",
              showMark: false,
              curve: "linear",
              area: true,
            },
          ]}
          height={400}
          // width={1000}
        ></LineChart>
      </CardContent>
    </Card>
  );
};
