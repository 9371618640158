import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Customer } from "./Customers";

export const RemoveCustomerDialog = (props: {
  showDialog: { open: boolean; row: Customer | null };
  closeDialog: () => void;
  handleDeleteClick: (id: string) => void;
}) => {
  const handleConfirm = () => {
    props.closeDialog();
    if (props.showDialog.row) props.handleDeleteClick(props.showDialog.row.id);
  };

  return (
    <Dialog open={props.showDialog.open} onClose={props.closeDialog}>
      <DialogTitle>Remove Customer</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Are you sure you want to remove customer ${
            props.showDialog.row ? props.showDialog.row.companyName : ""
          }?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.closeDialog}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
