import {
  GridActionsCellItem,
  GridColDef,
  GridDeleteIcon,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Row } from "./Customers";

export const getColumns = (
  rowModesModel: GridRowModesModel,
  handleSaveClick: (id: GridRowId) => () => void,
  handleCancelClick: (id: GridRowId) => () => void,
  handleEditClick: (id: GridRowId) => () => void,
  handleDeleteClick: (id: GridRowId) => () => void
) => {
  const columns: GridColDef[] = [
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      editable: true,
    },
    { field: "address", headerName: "Address", flex: 1, editable: true },
    {
      field: "billingAddress",
      headerName: "Billing Address",
      flex: 1,
      editable: true,
    },
    { field: "name", headerName: "Contact Name", flex: 1, editable: true },
    { field: "email", headerName: "Contact Email", flex: 1, editable: true },
    { field: "phone", headerName: "Contact Phone", flex: 0.6, editable: true },
    { field: "companyId", headerName: "Company ID", flex: 0.8, editable: true },
    { field: "createdAt", headerName: "Account Created", flex: 0.43 },
    { field: "updatedAt", headerName: "Updated At", flex: 0.43 },
    {
      field: "subscriptionPlan",
      headerName: "Subscription",
      flex: 0.5,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Trial", "Small", "Medium", "Large"],
    },
    {
      field: "subscriptionStatus",
      headerName: "Status",
      flex: 0.5,
      editable: true,
      type: "singleSelect",
      valueOptions: ["ACTIVE", "CANCELED", "INACTIVE"],
    },
    {
      field: "endDate",
      headerName: "End date",
      flex: 0.5,
      valueGetter: (_, row: Row) => {
        if (!row.subscriptions[row.subscriptions.length - 1]) {
          return null;
        }
        return row.subscriptions[row.subscriptions.length - 1].endDate;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.5,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<GridDeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return columns;
};
