import {
  CreditCardRounded,
  HomeRounded,
  PersonRounded,
  WebStoriesRounded,
} from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import Keycloak from "keycloak-js";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const listItems = [
  {
    text: "Home",
    icon: <HomeRounded />,
    url: "/",
    roles: ["client-admin", "client-user"],
  },
  {
    text: "Customers",
    icon: <PersonRounded />,
    url: "/customers",
    roles: ["client-admin"],
  },
];

export const MenuContent = (props: { keycloak: Keycloak | null }) => {
  const [roles, setRoles] = useState<string[]>();

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (index: number) => {
    navigate(listItems[index].url);
  };

  useEffect(() => {
    if (props.keycloak?.resourceAccess) {
      setRoles(props.keycloak.resourceAccess["pwelt-portal"].roles);
    }
  }, [props.keycloak]);

  return (
    <Stack
      sx={{
        flexGrow: 1,
        justifyContent: "space-between",
        marginTop: 2,
        p: 1,
      }}
    >
      <List sx={{ width: "100%" }}>
        {listItems.map(
          (item, index) =>
            item.roles.some((i) => roles?.includes(i)) && (
              <ListItem
                key={index}
                disablePadding
                sx={{ display: "block", padding: "0 4px" }}
              >
                <ListItemButton
                  sx={{
                    display: "flex",
                    gap: 2,
                    padding: "4px 8px",
                    borderRadius: 4,
                  }}
                  selected={location.pathname === item.url}
                  onClick={() => handleClick(index)}
                >
                  <ListItemIcon sx={{ minWidth: 0 }}>{item.icon}</ListItemIcon>
                  <ListItemText>{item.text}</ListItemText>
                </ListItemButton>
              </ListItem>
            )
        )}
      </List>
    </Stack>
  );
};
