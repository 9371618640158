import { Card, CardContent, Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { Subscription } from "./Home";

type ChartDataEntry = {
  date: number;
  [planName: string]: number | string;
};

const calcActiveSubsLastThirtyDays = (
  data: Subscription[],
  interval: number
) => {
  const chartData: ChartDataEntry[] = [];
  const currentDate = new Date();

  for (let i = 0; i <= interval; i++) {
    const date = new Date(currentDate);
    date.setDate(date.getDate() - i);
    const timeStamp = date.getTime();

    const dailyCount = data.reduce((acc, item) => {
      const isActiveOnDate =
        item.status === "ACTIVE" && new Date(item.startDate) <= date;

      if (isActiveOnDate) {
        const planName = item.plan.name;
        acc[planName] = (acc[planName] || 0) + 1;
      }
      return acc;
    }, {} as Record<string, number>);
    chartData.push({ date: timeStamp, ...dailyCount });
  }
  return chartData.reverse();
};

export const SubscriptionsGraph = (props: {
  data: Subscription[];
  interval: number;
}) => {
  return (
    <Card variant="outlined" sx={{ width: "100%" }}>
      <CardContent>
        <Typography component="h2" variant="subtitle1">
          Subscriptions
        </Typography>
        <LineChart
          dataset={calcActiveSubsLastThirtyDays(props.data, props.interval)}
          xAxis={[
            {
              id: "dates",
              dataKey: "date",
              valueFormatter: (date: Date) => {
                const temp: string[] = new Date(date).toDateString().split(" ");
                return temp[1] + " " + temp[2];
              },
              scaleType: "point",
              tickInterval: (_, i) => (i + 1) % (props.interval / 5) === 0,
            },
          ]}
          series={[
            {
              id: 1,
              label: "Trial",
              dataKey: "Trial",
              showMark: false,
              // curve: "linear",
            },
            {
              id: 2,
              label: "Small",
              dataKey: "Small",
              showMark: false,
              // curve: "linear",
            },
            {
              id: 3,
              label: "Medium",
              dataKey: "Medium",
              showMark: false,
              // curve: "linear",
            },
            {
              id: 4,
              label: "Large",
              dataKey: "Large",
              showMark: false,
              // curve: "linear",
            },
          ]}
          height={400}
          // width={1000}
        ></LineChart>
      </CardContent>
    </Card>
  );
};
