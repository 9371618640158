import {
  Box,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { StatusCard } from "./StatusCard";
import { StatBar } from "./StatBar";
import { SubscriptionsGraph } from "./SubscriptionsGraph";
import { UsersGraph } from "./UsersGraph";
import apiFetch from "../../../apiFetch";
import Keycloak from "keycloak-js";
import config from "../../../pweltconfig.json";

export type Subscription = {
  id: string;
  plan: {
    id: string;
    name: string;
    description: string;
    pricingMonthly: number;
    pricingYearly: number;
    numberOfUsers: number;
    storageLimit: number;
  };
  startDate: string;
  endDate: string;
  status: string;
};

export type DashboardData = {
  address: string;
  createdAt: string;
  subscriptions: Subscription[];
};

const getDashboardData = async (keycloak: Keycloak | null): Promise<any> => {
  const response = await apiFetch(
    `${config.REACT_APP_API_URL}/api/customers/dashboard`,
    keycloak
  );
  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`);
  }
  return response.json();
};

const flattenArray = (arr: DashboardData[]): Subscription[] =>
  arr
    .map((item) => item.subscriptions)
    .flat()
    .sort((a, b) => {
      if (a.startDate > b.startDate) return 1;
      if (b.startDate > a.startDate) return -1;
      return 0;
    });

export const Home = (props: { keycloak: Keycloak | null }) => {
  const [data, setData] = useState<DashboardData[]>([]);
  const [apiStatus, setApiStatus] = useState<string>("");
  const [interval, setInterval] = useState(30);

  const handleIntervalChange = (event: SelectChangeEvent) => {
    setInterval(+event.target.value);
  };

  useEffect(() => {
    getDashboardData(props.keycloak)
      .then((data) => {
        setData(data);
        setApiStatus("Online");
      })
      .catch((error) => {
        if (error instanceof Error) setApiStatus("Offline");
        else console.log(error);
      });
  }, [props.keycloak?.authenticated]);

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" }, pt: 5 }}>
      <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
        Dashboard
      </Typography>
      <StatBar data={flattenArray(data)} status={apiStatus}></StatBar>
      <FormControl sx={{ my: 2, width: 80 }}>
        <InputLabel id="interval-label">Days</InputLabel>
        <Select
          labelId="interval-label"
          id="interval"
          value={interval.toString()}
          label="Days"
          onChange={handleIntervalChange}
        >
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={90}>90</MenuItem>
          <MenuItem value={180}>180</MenuItem>
          <MenuItem value={365}>365</MenuItem>
        </Select>
      </FormControl>
      <Grid
        container
        spacing={6}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        <Grid size={{ md: 12, lg: 6 }}>
          <SubscriptionsGraph data={flattenArray(data)} interval={interval} />
        </Grid>
        <Grid size={{ md: 12, lg: 6 }}>
          <UsersGraph data={data} interval={interval} />
        </Grid>
        <Grid size={{ md: 6, lg: 3 }}>
          <StatusCard
            server={{
              serverName: "API",
              status: "Online",
              cpuUsage: 25,
              memoryUsage: 50,
              uptime: 95,
              responseTime: 2,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
