import Keycloak, { KeycloakConfig } from "keycloak-js";
import { useEffect, useState } from "react";
import config from "./pweltconfig.json";

const keycloakConfig: KeycloakConfig = {
  realm: "Pwelt-A1",
  url: config.REACT_APP_KEYCLOAK_URL,
  clientId: "assessment-app",
};

const useKeycloak = () => {
  const [keycloak, setKeycloak] = useState<Keycloak | null>(null);

  useEffect(() => {
    var keycloak = new Keycloak(keycloakConfig);

    keycloak
      .init({ onLoad: "login-required" })
      .then(() => setKeycloak(keycloak));
  }, []);

  return keycloak;
};

export default useKeycloak;
