import apiFetch from "../../../apiFetch";
import { Customer } from "./Customers";
import Keycloak from "keycloak-js";
import config from "../../../pweltconfig.json";

const baseUrl = config.REACT_APP_API_URL;

export const getCustomers = async (keycloak: Keycloak | null) => {
  const response = await apiFetch(`${baseUrl}/api/customers`, keycloak)
    .then((response) => response.json())
    .then((data: Customer[]) => {
      return data;
    })
    .catch((err) => {
      return [];
    });
  return response;
};

export const deleteSelected = async (ids: string[], keycloak: Keycloak) => {
  const response = await apiFetch(`${baseUrl}/api/customers/remove`, keycloak, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(ids),
  });
  if (response.ok) console.log(response.body);
  else console.log("Error");
};

export const deleteRow = async (id: string, keycloak: Keycloak | null) => {
  const res = await apiFetch(
    `${baseUrl}/api/customers/${id}/remove`,
    keycloak,
    {
      method: "DELETE",
    }
  );
  return res;
};

export const saveEditedRow = async (
  customer: Customer,
  keycloak: Keycloak | null
) => {
  try {
    const response = await apiFetch(
      `${baseUrl}/api/customers/update`,
      keycloak,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(customer),
      }
    );
    if (!response.ok) {
      return "Could not update!";
    }

    const data: Customer = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};
