import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Stack,
  Typography,
} from "@mui/material";
import { MenuContent } from "./MenuContent";
import { DarkModeSwitch } from "./DarkModeSwitch";
import Keycloak from "keycloak-js";
import { useEffect, useState } from "react";

const drawerWidth = 300;

export const SideMenu = (props: {
  changeMode: () => void;
  handleLogout: () => void;
  keycloak: Keycloak | null;
}) => {
  const [username, setUsername] = useState<string>();

  useEffect(() => {
    props.keycloak?.loadUserProfile().then((profile) => {
      setUsername(profile.username);
    });
  }, [props.keycloak]);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        flexShrink: 0,
        width: drawerWidth,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      {props.keycloak?.authenticated && (
        <Box sx={{ display: "flex", p: 1.5 }}>
          <Stack
            direction="row"
            sx={{
              p: 1,
              gap: 2,
              alignItems: "center",
            }}
          >
            <Box sx={{ mr: "auto" }}>
              <Typography>{username}</Typography>
            </Box>
          </Stack>
        </Box>
      )}
      <Divider />
      <MenuContent keycloak={props.keycloak} />
      <Stack direction="row" justifyContent="space-between" padding={1}>
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={props.handleLogout}
        >
          Logout
        </Button>
        <DarkModeSwitch onClick={props.changeMode} />
      </Stack>
    </Drawer>
  );
};

export default SideMenu;
