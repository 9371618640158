import Keycloak from "keycloak-js";

const apiFetch = async (
  url: string,
  keycloak: Keycloak | null,
  options?: RequestInit
) => {
  if (options === undefined) options = {};
  const headers = new Headers(options.headers || {});
  if (keycloak && keycloak.token) {
    await keycloak.updateToken(30);
    headers.set(`Authorization`, `Bearer ${keycloak.token}`);
  }

  return fetch(url, {
    ...options,
    headers,
  });
};

export default apiFetch;
