import { Box, Snackbar } from "@mui/material";
import {
  DataGrid,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridToolbar,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Subscription } from "../home/Home";
import { RemoveCustomerDialog } from "./RemoveCustomerDialog";
import { getColumns } from "./GridColumns";
import { deleteRow, getCustomers, saveEditedRow } from "./fetchFunctions";
import Keycloak from "keycloak-js";

export type Customer = {
  id: string;
  companyName: string;
  address: string;
  billingAddress: string;
  name: string;
  email: string;
  phone: string;
  companyId: string;
  subscriptions: Subscription[];
  createdAt: string;
  updatedAt: string;
};

export type Row = {
  subscriptionId: string;
  subscriptionPlan: string;
  subscriptionStatus: string;
} & Customer;

export const Customers = (props: { keycloak: Keycloak | null }) => {
  const [rows, setRows] = useState<Row[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [showNotif, setShowNotif] = useState({ open: false, message: "" });
  const [showDialog, setShowDialog] = useState<{
    open: boolean;
    row: Customer | null;
  }>({ open: false, row: null });

  const closeDialog = () => {
    setShowDialog({ ...showDialog, open: false });
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const clickedRow = rows.find((r) => r.id === id);
    if (clickedRow !== undefined) {
      setShowDialog({ open: true, row: clickedRow });
    }
  };

  const deleteConfirm = (id: string) => {
    deleteRow(id.toString(), props.keycloak).then((res) => {
      if (res.ok) {
        setRows(rows.filter((row) => row.id !== id));
        setShowNotif({ open: true, message: "Customer successfully removed!" });
      } else
        setShowNotif({ open: true, message: "Customer could not be removed!" });
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow: Row, oldRow: Row) => {
    if (JSON.stringify(newRow) === JSON.stringify(oldRow)) return oldRow;

    if (
      newRow.subscriptionStatus !== oldRow.subscriptionStatus &&
      newRow.subscriptionPlan !== oldRow.subscriptionPlan
    ) {
      setShowNotif({
        open: true,
        message: "Cannot upadate status and plan simultaneously!",
      });
      return oldRow;
    }

    const {
      subscriptionId,
      subscriptionPlan,
      subscriptionStatus,
      ...customer
    } = newRow;

    const sub = customer.subscriptions[customer.subscriptions.length - 1];
    sub.plan.name = subscriptionPlan;
    sub.status = subscriptionStatus;

    const res = await saveEditedRow(customer, props.keycloak);

    if (typeof res === "string" || typeof res === "undefined") {
      setShowNotif({
        open: true,
        message: "Error! Could not update customer!",
      });
    } else {
      const sub: Subscription =
        res?.subscriptions[res.subscriptions.length - 1];
      const row = {
        ...res,
        subscriptionId: sub?.id,
        subscriptionPlan: sub?.plan.name,
        subscriptionStatus: sub?.status,
      };
      setRows([...rows, row]);
      newRow = row;
      setShowNotif({ open: true, message: `${row.companyName} updated!` });
    }

    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    getCustomers(props.keycloak).then((res) => {
      const rows = res.map((customer) => {
        const sub = customer.subscriptions[customer.subscriptions.length - 1];
        return {
          ...customer,
          subscriptionId: sub?.id,
          subscriptionPlan: sub?.plan.name,
          subscriptionStatus: sub?.status,
        };
      });
      setRows(rows);
    });
  }, []);

  return (
    <Box width="100%" height="100vh">
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={showNotif.open}
        onClose={() => setShowNotif({ open: false, message: "" })}
        message={showNotif.message}
      />
      <RemoveCustomerDialog
        closeDialog={closeDialog}
        showDialog={showDialog}
        handleDeleteClick={deleteConfirm}
      />
      <Box sx={{ width: "100%", height: "100%" }}>
        {rows.length !== 0 && (
          <DataGrid
            rows={rows}
            columns={getColumns(
              rowModesModel,
              handleSaveClick,
              handleCancelClick,
              handleEditClick,
              handleDeleteClick
            )}
            initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            processRowUpdate={processRowUpdate}
            onRowEditStop={handleRowEditStop}
            pageSizeOptions={[10, 25, 50, 100]}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};
